import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p>{`Search enables users to specify a word or a phrase to find relevant pieces of content without the use of navigation. Search can be used as the primary means of discovering content, or as a filter to aid the user in finding content.`}</p>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Search type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Purpose`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Large search`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This should be used at a global level when the user is searching content within a page view.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Small search`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Choose small when there are space constraints in your design. It can also be component-specific. For example, small search can be used to filter data within a `}<a parentName="td" {...{
              "href": "/components/data-table"
            }}>{`data table`}</a>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "format"
    }}>{`Format`}</h2>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAABlUlEQVQoz62TW07bQBSGZwktUt+7hIoFELEapKrliTUgeOkSuoaqqugGGqhKEq5JVGwLmsR2EseX+JJg+8z8HE/CRZSXqvmlT2fOP2dmNDozQqxaUkqRJImI4/gh/g+CSNZ58E8kSVyPpkk9iB74wRwz7wS01COqQr7AwucbgRiAnlAstqF4U8xuJd3YPjOh64GnY88N/uKPwzVOSNNpTLM0oi+/Mtr9mtOn7zntfcuL/QMFzjdEkBJOuj2cdq5x1r3B5VUfHdPBpTFAW2Oja7m4+N1Hs+tgNPaQhkNsfZ5h7YPE251CvflYytfbwKv3ZU3keQ7DtDTN1gnqh0c4PTuHYZi4YtrtDg6PfupoVnWGAcuy0Go10Wg02DOVaRpy0O/BMo0aN4WU7/sax3HU0HXVZDLRecCMx2Nl27byl979XOW5XB8s1sosTcGxVj0b4uei8TyPwjCkNE2Ju6m9KIq0X+X3VP5wNCI+bOklxXw+B483ll1eqCxL3cXnKoriRa+qf6osyzarDRsr4Jg5Z9ZX/vPuACfJzLOcJdgSAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Search at the top of the data table",
            "title": "Search at the top of the data table",
            "src": "/static/db9531a5ae4a36a53b46579d1886fd3f/fb070/search-usage-1.png",
            "srcSet": ["/static/db9531a5ae4a36a53b46579d1886fd3f/d6747/search-usage-1.png 288w", "/static/db9531a5ae4a36a53b46579d1886fd3f/09548/search-usage-1.png 576w", "/static/db9531a5ae4a36a53b46579d1886fd3f/fb070/search-usage-1.png 1152w", "/static/db9531a5ae4a36a53b46579d1886fd3f/c3e47/search-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h3 {...{
      "id": "search-fields"
    }}>{`Search fields`}</h3>
    <p>{`Set the user’s context for the search with helpful placeholder text within the search field (search documents), that pertains to the page or section the search box is in. Search in the global header should just say search.`}</p>
    <p>{`Accessibility note: If the search component’s label and placeholder text differ, VoiceOver on macOS will read both.`}</p>
    <h3 {...{
      "id": "search-results"
    }}>{`Search results`}</h3>
    <ul>
      <li parentName="ul">{`Clearly show which results are being displayed with labels or headers (i.e. Results for X).`}</li>
      <li parentName="ul">{`Categorize the search results if they come from different areas (eg. docs, services, apps, tutorials, blogs).`}</li>
      <li parentName="ul">{`Give the user control over their viewing options and ability to sort results (eg. relevancy, popularity, ratings, date).`}</li>
      <li parentName="ul">{`Have a clear, “no results,” message and offer suggestions if possible.`}</li>
      <li parentName="ul">{`Have smart algorithms that can search for similar terms or can search even with misspelled words.`}</li>
      <li parentName="ul">{`Include enough of a description in the results for users to make a judgement as to whether that particular result is relevant.`}</li>
      <li parentName="ul">{`Highlight the search term in the results, where appropriate.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      